<template>
  <li class="dropdown hover-line language-switcher">
    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
      <img :src="currentLang.flag" /> {{ currentLang.name }}
    </a>
    <ul class="dropdown-menu languages">
      <li :class="{ active: lang.key === currentLang.key }" v-for="lang in langList" :key="lang.key">
        <a href="javascript:void(0)" @click="changeLang(lang)">
          <img :src="lang.flag" /> {{ lang.name }}
        </a>
      </li>
    </ul>
</li>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  props: {
    currentLang: {
      type: Object,
      required: true
    },
    langList: {
      type: Array,
      required: true
    }
  },
  methods: {
    changeLang(lang) {
      this.$emit('change-lang', lang);
    }
  }
};
</script>

<style scoped>
/* 添加必要的样式 */
</style>