<template>
    <span class="icon-container">
        <!-- <svg width="40" height="40"  version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <rect width="100%" height="100%" fill="blue" />
                  <text x="50%" y="50%" text-anchor="middle" alignment-baseline="middle" font-size="12" fill="black">A</text>
                </svg> -->
        <svg width="40" height="40" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <!-- 浅灰色圆形背景 -->
            <circle cx="20" cy="20" r="18" :fill="getColor" />

            <!-- 中间字母A 垂直居中 -->
            <text x="50%" y="50%" text-anchor="middle" dominant-baseline="central"
                font-size="20" fill="black">{{ getTitle }}</text>
        </svg>
    </span>
</template>

<style scoped>
.icon-container {
  border-radius: 50%;
  width: 40px;
  float: left;
  margin-right: 10px;
  display: block;
}
</style>
<script>
export default {
    name: 'Icon1',
    data() {
        return {
            colors: ['#fc5185', '#3fc1c9', '#aa96da', '#a8d8ea', '#eaffd0', '#f08a5d', '#f8f3d4'],
        };
    },
    props: {
        // 标题
        title: {
            type: String,
            default: "A"
        }
    },
    computed: {
        // 获取标题
        getTitle() {
            // return this.title;
            // 取标题的第一个字母或汉字
            let first_char = this.title.substr(0, 1);
            // 转大写
            first_char = first_char.toUpperCase();
            return first_char;
            // return this.title.match(/[\u4e00-\u9fa5]|./)[0];
        },
        // 获取颜色
        getColor() {
            // 随机颜色
            // return this.colors[Math.floor(Math.random() * this.colors.length)];
            // 根据首字母计算出颜色
            return this.colors[this.getFirstCharCode(this.getTitle) % this.colors.length];
        }
    },
    methods: {
        getFirstCharCode(str) {
            // 获取第一个字符
            const char = str.match(/[\u4e00-\u9fa5]|./)[0];
            // 获取字符的unicode编码
            const charCode = char.charCodeAt();
            // 如果是中文
            if (charCode >= 0x4e00 && charCode <= 0x9fa5) {
                // 中文字符的unicode编码从0x4e00开始，共2090个
                return charCode - 0x4e00;
            }
            // 将ASCII字符映射到0-2090范围内
            return charCode % 2091;
        }
    }

}
</script>
