import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import About from '../views/about.vue'
import Develop from '../views/develop.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Index },
  { path: '/about', component: About },
  { path: '/develop', component: Develop }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router